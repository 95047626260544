@charset "UTF-8";
/* Icons */
@font-face {
  font-family: "slick";
  src: url("../media/slick.eot");
  src: url("../media/slick.eot?#iefix") format("embedded-opentype"), url("../media/slick.woff") format("woff"), url("../media/slick.ttf") format("truetype"), url("../media/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  border-radius: 100%;
  color: #2f91e9;
  top: 33%;
  padding: 0;
  border: none;
  outline: none;
  z-index: 10; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none; }
    .slick-prev:hover::before, .slick-prev:focus::before,
    .slick-next:hover::before,
    .slick-next:focus::before {
      opacity: 1; }
  .slick-prev.slick-disabled::before,
  .slick-next.slick-disabled::before {
    color: #c8c8c8; }
  .slick-prev::before,
  .slick-next::before {
    font-family: "slick";
    font-size: 42px;
    line-height: 1;
    color: white;
    color: #2f91e9;
    background-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -40px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -36px; }
  .slick-prev::before {
    content: "←"; }
    [dir="rtl"] .slick-prev::before {
      content: "→"; }

.slick-next {
  right: -30px; }
  [dir="rtl"] .slick-next {
    left: -36px;
    right: auto; }
  .slick-next::before {
    content: "→"; }
    [dir="rtl"] .slick-next::before {
      content: "←"; }

@media (max-width: 576px) {
  .slick-prev,
  .slick-next {
    height: 20px;
    width: 20px; }
    .slick-prev::before,
    .slick-next::before {
      font-size: 32px; }
  .slick-prev {
    left: -28px; }
    [dir="rtl"] .slick-prev {
      left: auto;
      right: -24px; }
    .slick-prev::before {
      content: "←"; }
      [dir="rtl"] .slick-prev::before {
        content: "→"; }
  .slick-next {
    right: -14px; }
    [dir="rtl"] .slick-next {
      left: -24px;
      right: auto; } }

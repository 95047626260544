@charset "UTF-8";
@import "../vars";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "../media/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "../images/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
  @return url($slick-loader-path + $url);
}

@function slick-font-url($url) {
  @return url($slick-font-path + $url);
}

/* Icons */
@if $slick-font-family == "slick" {
  @font-face {
    font-family: "slick";
    src: slick-font-url("slick.eot");
    src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  border-radius: 100%;
  color: $blue;
  top: 33%;
  //content: "\2022";
  //-webkit-transform: translate(0, -50%);
  //-ms-transform: translate(0, -50%);
  //transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 10;

  &:hover,
  &:focus {
    outline: none;

    &::before {
      opacity: $slick-opacity-on-hover;
    }
  }

  &.slick-disabled::before {
    color: $border-grey;
  }

  &::before {
    font-family: $slick-font-family;
    font-size: 42px;
    line-height: 1;
    color: $slick-arrow-color;
    color: $blue;
    background-color: transparent;
    //opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: -40px;

  [dir="rtl"] & {
    left: auto;
    right: -36px;
  }

  &::before {
    content: $slick-prev-character;

    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: -30px;

  [dir="rtl"] & {
    left: -36px;
    right: auto;
  }

  &::before {
    content: $slick-next-character;

    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}

@media (max-width: 576px) {
  .slick-prev,
  .slick-next {
    height: 20px;
    width: 20px;

    &::before {
      font-size: 32px;
    }
  }

  .slick-prev {
    left: -28px;

    [dir="rtl"] & {
      left: auto;
      right: -24px;
    }

    &::before {
      content: $slick-prev-character;

      [dir="rtl"] & {
        content: $slick-next-character;
      }
    }
  }

  .slick-next {
    right: -14px;

    [dir="rtl"] & {
      left: -24px;
      right: auto;
    }
  }
}

@import "../../vars.scss";
$black-font: #212529;

.top-menu {
  font-size: 13px;
  padding: 12px 0;

  &--text {
    padding-top: 4px;
  }

  &--right {
    text-align: right;
  }

  &--link {
    padding-left: 1em;
    cursor: pointer;

    &--disabled {
      cursor: none;
      color: #828282;
    }
  }

  &--link {
    cursor: pointer;
  }
}

.auth-button {
  @extend %button;
  width: 30px;
  margin-left: 16px;
}

@import "../../../vars.scss";

.view-switcher {
  text-align: right;

  &--link {
    @extend %button;
    border-radius: 100%;
    display: inline-block;
    height: 36px;
    margin-left: 4px;
    width: 36px;

    &--active {
      @extend %button-active;
    }
  }
}

@media (max-width: 992px) {
  .view-switcher {
    width: 50% !important;

    &--link {
      margin-left: 8px;
    }
  }
}

.page-wrapper {
  margin-bottom: -150px;
  min-height: 85%;

  &::after {
    content: '';
    display: block;
    height: 150px;
  }
}

@media (max-width: 992px) {
  .page-wrapper {
    margin-bottom: -130px;

    &::after {
      height: 130px;
    }
  }
}

@media (max-width: 576px) {
  .page-wrapper {
    margin-bottom: -100px;

    &::after {
      height: 100px;
    }
  }
}
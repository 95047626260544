.site-footer {
  background: center center repeat-x;
  background-size: contain;
  height: 150px;
}

@media (max-width: 992px) {
  .site-footer {
    height: 130px;
  }
}

@media (max-width: 576px) {
  .site-footer {
    height: 100px;
  }
}

@import "../../vars.scss";

$dark-blue: #1f4787;

.copyright {
  background-color: $white;
  border-radius: 4px 4px 0 0;
  bottom: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, .35);
  padding: 0 14px;
  position: fixed;
  right: 7.5%;

  &--text {
    color: $dark-blue;
    font-size: .92em;

    &:hover {
      color: $dark-blue;
    }
  }
}

@media (max-width: 768px) {
  .copyright {
    left: inherit;
    right: 22%;
    text-align: center;
    width: 56%;

    &--text {
      font-size: .8em;
      line-height: 1.2;
    }
  }
}
.cams-menu {
  font-size: 13px;
  padding: 2em 0 3em !important;
}

@media (max-width: 992px) {
  .cams-menu {
    padding: 2em 0 2em !important;

    &--text {
      width: 50% !important;
    }
  }
}

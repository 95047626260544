@import "../../../vars.scss";

.search-field {
    @extend %button;
    width: 260px;
    font-size: 13px;
    padding: 0 1em;
    margin: 4px;

    &:focus {
        outline: 0;
    }
}

@media (max-width: 768px) {
    .search-field {
        width: 50%;
    }
}

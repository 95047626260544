@import "../../../vars.scss";

.paginator {
  font-size: 14px;
  padding: 2em 0;
  text-align: center;

  &--item {
    @extend %button;
    display: inline-block;
    height: 40px;
    padding-top: 4px;
    width: 40px;

    &--active {
      @extend %button-active;
    }
  }
}

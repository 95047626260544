$black-font: #212529;

@font-face {
  font-family: 'Gotham Pro';
  src: url('./media/GothamPro.eot');
  src: url('./media/GothamPro.eot?#iefix') format('embedded-opentype'),
  url('./media/GothamPro.woff') format('woff'),
  url('./media/GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro Bold';
  src: url('./media/GothamPro-Bold.eot');
  src: url('./media/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('./media/GothamPro-Bold.woff') format('woff'),
  url('./media/GothamPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

html,
body, #root {
  height: 100%;
}

body {
  font-family: 'Gotham Pro';
}

a {
  color: $black-font;

  &:hover {
    color: $black-font;
    text-decoration: none;
  }
}

h1,
h2 {
  font-weight: 400;
  margin: 0;
  padding-bottom: .7em;
  text-align: center;
  text-transform: uppercase;
}

h2 {
  font-size: 1.8em;
  padding: .7em 0;
}

button {
  cursor: pointer;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }
}

p {
  text-align: justify;
}

img {
  width: 100%;
}

main .container {
  max-width: 1280px !important;
}

@media (max-width: 768px) {
  h1 {
    padding: .7em 0;
  }
}
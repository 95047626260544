.site-header {
  background-color: #2f91e9;
  color: #fff;
  padding: 1.6em 0 2.6em;
}

.site-logo {
  &--img {
    width: 26px;
    height: 26px;
    border: 3px solid white;
    border-radius: 50%;
    padding: 8px;
    box-sizing: content-box;
    margin-right: 8px;
  }

  &--text {
    color: #fff;
    font-family: "Gotham Pro Bold";
    font-size: 1.7rem;
  }
}

@media (max-width: 768px) {
  .site-header {
    padding: 1.6em 0;
  }
}
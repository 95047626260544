$white: #fff;
$red: #e5393f;
$grey: #c8c8c8;

.nav-menu-wrapper {
  max-width: 620px !important;
  position: relative;
  top: -25px;
}

.nav-menu {
  background-color: $white;
  border-radius: 2em;
  box-shadow: 0 2px 5px $grey;
  position: relative;
  z-index: 10;

  &--item {
    display: block;
    font-size: .9em;
    text-align: center;

    &--current {
      background-color: $red;
      border-radius: 2em;
      color: $white;
    }
  }

  &--link {
    display: block;
    padding: 14px 0;
    text-transform: uppercase;
  }

  &--item--current &--link {
    color: $white;
  }

  &--no-shadow {
    box-shadow: none;
  }
}

@media (max-width: 768px) {
  .nav-menu-wrapper {
    max-width: 100% !important;
    position: static;
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: 100%;
  }
  .nav-menu {
    border-radius: 0;

    &--item {
      font-size: .65em;
      padding: 14px 0;
      text-align: center;

      &--current {
        border-radius: 0;
        border: 1px solid $red;
      }
    }

    &--link {
      padding: 0;
    }
  }
}

@import "../../../vars.scss";

.hashtag {
  margin-bottom: 2em;

  &-item {
    @extend %button;
    padding: 0 1em;
    margin: 4px;
    font-size: 13px;

    &--active {
      @extend %button-active;
    }
  }
  &-switcher {
    background-color: $white;
    border: 0;
    color: $red;
    margin-left: 4px;
    font-size: 1.2em;

    &--triangle {
      width: 16px;
    }

    &--active {
      transform: rotate(180deg);
    }
  }
}

@media (max-width: 992px) {
  .hashtag-item {
    padding: 4px 1em;
    margin: 5px 4px;
  }
}

.video-js,
.vjs-tech {
  border-radius: 12px;

  video {
    object-fit: fill;
  }

  .vjs-text-track-display {
    background: url("/static/logo_tim.png") no-repeat 99% 2%;
    background-size: 80px 40px;
  }

  .vjs-control-bar {
    border-radius: 0 0 12px 12px;
    height: 240px !important;
    padding-top: 208px;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.42) 100%) 100% 100% / 100% 240px no-repeat transparent !important;

    & .vjs-live-display {
      font-size: 1.55em;
      font-family: "Gotham Pro Bold";
      text-transform: uppercase;
      line-height: 2em;
    }

    & .vjs-progress-control,
    & .vjs-picture-in-picture-control {
      display: none !important;
    }
  }
}

@media (max-width: 1280px) {
  .video-js .vjs-text-track-display {
    background-size: 48px 24px;
  }
}
@import "../../vars.scss";

.private-cam-wrapper {
  margin-bottom: 2em;
}

.playlist-link {
  $cam-border: #eaeaea;
  min-height: 194px;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  height: 100%;

  &--text {
    background: $blue;
    border-radius: 0 0 8px 8px;
    color: $white;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-family: "Gotham Pro Bold";
    min-height: 48px;
    margin-bottom: 1em;
    text-align: center;
    text-transform: uppercase;
    transition: all .5s ease;
    width: 100%;
  }

  &--logo {
    align-items: center;
    border: 1px solid $cam-border;
    border-radius: 8px 8px 0 0;
    display: flex;
    height: 144px;
    justify-content: center;
    object-fit: contain;
    padding: 1em 1.5em;
    width: 100%;
  }
}

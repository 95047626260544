@import "../../vars.scss";

.modal {
  outline: none;
  background-color: white !important;
  font-size: 1.6rem;
  width: 40rem;
  max-width: 90%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 100;
  min-height: 10rem;
  border-radius: 8px;
  padding: 48px 0;

  &--text {
    font-size: 14px;
    text-align: center;

    &--error {
      color: $red;
      padding: 12px 0 0 4px;
      margin: 0;
    }
  }

  &--input-wrapper {
    padding-bottom: 8px;
  }

  &--input {
    width: 100%;
    height: 36px;
    border-radius: 20px;
    border: 1px solid $border-grey;
    padding: 8px 16px;
    font-size: 0.5em;
    outline: 0;
    margin: 0;
  }

  &--submit-button {
    @extend %button;
    background-color: $red;
    border: 1px solid $white;
    color: $white;
    width: 100%;
    height: 36px;
    font-size: 1rem;
    font-weight: bold;
  }
}

@media (max-width: 768px) {
  .modal {
    width: 90%;
    max-width: 90%;
    margin-bottom: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 48px 16px;

    &--text--error {
      text-align: center;
      padding: 12px 0;
    }
  }
}
@import "../../vars";

.playlist-header {
  position: relative;
  top: -50px;
  width: 100%;
  z-index: 5;

  &--img {
    height: 200px;
    object-fit: cover;
  }

  &--no-img {
    background-color: $yellow;
  }

  &--text {
    color: $white;
    font-family: "Gotham Pro Bold";
    font-size: 2rem;
    padding: 1.5em 0 1em;
  }
}

@media (max-width: 992px) {
  .playlist-header {
    top: -49px;

    &--img {
      height: 140px;
    }
  }
}

@media (max-width: 768px) {
  .playlist-header {
    position: static;

    &--img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    &--img,
    &--no-img {
      margin-bottom: 1.5rem;
    }

    &--text {
      font-size: 1.2em;
      padding: 1em 0;
    }
  }
}

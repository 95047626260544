$cam-height: 164px;
$white: #fff;

.cam {
  background-color: #DFDFDF;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  margin-bottom: 16px;
  max-height: $cam-height;
  min-height: $cam-height;
  overflow: hidden;
  position: relative;

  &--title {
    color: $white;
    font-size: 2em;
    position: absolute;
    z-index: 0;
  }

  &--img {
    max-height: 100%;
    max-width: 100%;
    min-height: $cam-height;
    min-width: 100%;
    transition: all .5s ease;
  }

  &--text {
    $shadow: #4b4b4b;
    background: linear-gradient(180deg, transparent 0, $shadow);
    border-radius: 8px;
    bottom: 0;
    color: $white;
    display: inherit;
    font-family: "Gotham Pro Bold";
    padding: 4px 8px;
    position: absolute;
    text-transform: uppercase;
    transition: all .5s ease;
    width: 100%;
  }

  &:hover {
    .cam--img {
      border-radius: 8px;
      transform: scale(1.05);
    }

    .cam--text {
      $shadow: #404040;
      background: linear-gradient(180deg, transparent 0, $shadow);
    }
  }
}

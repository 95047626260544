.map {
  border-radius: 8px;
  height: 640px;
  margin-bottom: 36px;
  padding-left: 0;
  padding-right: 0;

  &--popup {
    width: 260px;
    height: 190px;
  }
}

.map-wrapper {
  margin-left: 0;
  margin-right: 0;
}

.leaflet-popup-content {
  margin: 12px 4px;
}

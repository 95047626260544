$blue: #2f91e9;
$red: #e5393f;
$white: #fff;
$border-grey: #c8c8c8;
$grey-text-color: #828282;
$yellow: #ffad00;


%button {
  background-color: $white;
  border: 1px solid $border-grey;
  border-radius: 20px;
  margin: 0 4px;
  transition: .2s;
}

%button-active {
  background-color: $red;
  border: 1px solid $red;
  color: $white;
}

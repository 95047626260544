@import "../../../vars.scss";

.per-page-switcher {
  text-align: center;

  &--hidden {
    display: none;
  }

  &--link {
    @extend %button;
    padding: 8px 28px;
    display: inline;

    &--active {
      @extend %button-active;

      &:hover {
        color: $white;
      }
    }
  }
}

.carousel {
  margin-bottom: 2em !important;

  &--header {
    text-transform: capitalize;
    padding-left: 1em;
  }
}

@media (max-width: 576px) {
  .carousel {
    margin: 0 1em 2em;

    &--header {
      margin-left: 2em;
    }
  }
}
